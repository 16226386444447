// API
export const DOMAIN = "";
export const BASE_URL = "https://api.admin.spindev.web.id/";
export const BASE_URL_IMG = "https://api.image.v2.spindev.web.id/";

// PARAM
export const BASE_YOUTUBE_LINK = "https://www.youtube.com/watch?v="

// REGEX
const regex = (reg) => new RegExp(reg);
export const regexNumber = regex(`^([0-9])+$`);