import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL, BASE_URL_IMG, DOMAIN } from "./Constant";


const callRefreshToken = async (refresh_token) => {
    return axios
        .create({ baseURL: BASE_URL, responseType: "json" })
        .post("auth/refreshtoken", { refresh_token })
        .then((res) => {
            if (res.data.status === 0) return res.data;
            const { access_token, refresh_token } = res.data.data;
            Cookies.set("acc_ad_tkn", access_token, { secure: true, domain: DOMAIN });
            Cookies.set("rfsh_ad_tkn", refresh_token, { secure: true, domain: DOMAIN });
            return res.data;
        });
};

const AuthInInterceptor = (axiosObj) => {
    axiosObj.interceptors.request.use(
        (req) => {
            req.headers.Authorization = "Bearer " + Cookies.get("acc_ad_tkn");
            return req;
        },
        (err) => Promise.reject(err)
    );

    axiosObj.interceptors.response.use(
        (res) => res,
        async (err) => {
            const oriReq = err.config;
            if (err.code === "ERR_CANCELED") return axiosObj(oriReq);
            const status = err.response.status;
            if ([401, 500].includes(status) && !oriReq._retry) {
                oriReq._retry = true;
                const res = await callRefreshToken(Cookies.get("rfsh_ad_tkn"));
                if (res.status === 0) Promise.reject(res.message);
                return axiosObj(oriReq);
            }
            return Promise.reject(err.response);
        }
    );

    return axiosObj;
};



const DefAxios = { baseURL: BASE_URL, responseType: "json" };
export const API = axios.create(DefAxios);
export const APIFile = axios.create({ ...DefAxios, headers: { "content-type": "multipart/form-data" } });
export const APIAuth = AuthInInterceptor(API);
export const APIAuthFile = AuthInInterceptor(APIFile);
export const APIImg = axios.create({
    baseURL: BASE_URL_IMG,
    headers: { "content-type": "multipart/form-data" },
    responseType: "json",
});