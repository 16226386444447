import Cookies from "js-cookie";
import { defaultThemes } from "react-data-table-component";
import Swal from "sweetalert2";
import { API, APIAuth } from "../utils/API";
import { DOMAIN } from "../utils/Constant";
import { globalStorage } from "../utils/Storage";

export async function Login(username, password) {
  return API.post(`auth/login`, { username, password }).then((res) => {
    const data = res.data;
    if (res.data.status === 0) return res.data;
    Cookies.set("acc_ad_tkn", data.data.access_token, { secure: true, domain: DOMAIN });
    Cookies.set("rfsh_ad_tkn", data.data.refresh_token, { secure: true, domain: DOMAIN });
    globalStorage.setItem('UD', data.data.user);
    return res.data;
  })
}

export async function Logout() {
  Cookies.remove("acc_ad_tkn", { secure: true, domain: DOMAIN });
  Cookies.remove("rfsh_ad_tkn", { secure: true, domain: DOMAIN });
  globalStorage.removeItem("UD");
  window.location = "/login";
}

export async function getUserProfile() {
  return APIAuth.get(`auth/profile`).then((res) => {
    const data = res.data;
    if (res.data.status === 0) return undefined;
    globalStorage.setItem('UD', data.data);
    return data.data;
  }).catch((err) => undefined);
}

export async function Alert(icon, text) {
  return Swal.fire({
    icon,
    text,
    allowOutsideClick: false,
    cancelButtonText: "Batal",
    showCancelButton: true,
    confirmButtonText: "Ok",
    confirmButtonColor: "var(--main_color)",
    cancelButtonColor: "#d33",
  }).then((res) => res);
}

export const tableStyle = {
  head: {
    style: { position: 'sticky', top: 0, zIndex: 30, backgroundColor: 'white' }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: { fontWeight: "600", fontSize: "1rem", padding: 0 },
  },
  cells: {
    style: { padding: `0.2rem 0.5rem`, alignItems: 'flex-start' }
  },
  pagination: {
    style: {
      minHeight: "1rem",
      padding: "0.25rem 8px",
    },
    pageButtonsStyle: {
      padding: "0",
      height: "20px",
    },
  },
};