import Cookies from 'js-cookie';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getUserProfile } from './controller/GlobalController';
import { globalStorage } from './utils/Storage';
import "react-datepicker/dist/react-datepicker.css";
require('dayjs/locale/id');

// component
const Login = React.lazy(() => import("./page/Login/Index"));
const BasicLayout = React.lazy(() => import("./component/BasicLayout/BasicLayout"));

function AuthComponent(component) {
  const token = Cookies.get("acc_ad_tkn");
  const user = globalStorage.getItem('UD');
  const path = window.location.pathname;
  if (token && [undefined, null, ""].includes(user)) getUserProfile();
  if (!token) return <Navigate to="/login" state={{ prev: path }} />;
  return component;
}

function App() {
  return (
    <div className="app_container">
      <Suspense fallback={<>Loading</>}>
        <Routes>
          <Route exact path="/" element={<Navigate to={"/dashboard"} />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={AuthComponent(<BasicLayout />)} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
